import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../../constants';

const Dashboard = () => {
    const notify = () => toast("Wow so easy!");
    const location = useLocation();
    const [responseData, setResponseData] = useState(null);
    const [socialLiteUserData, setSocialLiteUserData] = useState('');
    const [formData, setFormData] = useState({
        email: '',
        date: '',
        time: '',
        message: ''
    });
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        const parseQueryParams = (query) => {
            const params = new URLSearchParams(query);
            return JSON.parse(params.get('data'));
        };
        const queryParams = parseQueryParams(location.search);
        if (queryParams && queryParams.social_lite_user) {
            setResponseData(queryParams);
            setSocialLiteUserData(JSON.stringify(queryParams.social_lite_user, null, 2));
            console.log('Response Data:', queryParams);
        }
    }, [location.search]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const requestData = {};
        requestData.email = formData.email;
        requestData.message = formData.message;
        requestData.datetime = new Date(`${formData.date} ${formData.time}`).toISOString();

        const bearerToken = localStorage.getItem('token');
        fetch(`${API_URL}/message/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearerToken}`,
                'Accept': 'application/json' // Adding Accept header for JSON response
            },
            body: JSON.stringify(requestData),
        })
        .then(response => {
            if (response.ok) {
                console.log("here")
                toast('Message scheduled successfully');
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            } else {
                response.json().then(data => {
                    setFormErrors(data.errors || {});
                });
                toast.error('Failed to schedule message');
            }
        })
        .catch(error => {
            console.error('Error sending message:', error);
            toast.error('Error sending message');
        });
    };

    return (
        <div className="container d-flex justify-content-center mt-5">
        <ToastContainer />
   <div className="card p-4 mb-5" style={{ maxWidth: '500px', borderRadius: '15px' }}>
       <h2 className="text-center mb-4">Dashboard</h2>
       <h3>User Data</h3>
       <textarea
           value={socialLiteUserData}
           rows={10}
           cols={50}
           readOnly
           className="form-control mb-4"
       />
       <h3>Message Form</h3>

       {/* Bootstrap Form */}
       <form onSubmit={handleSubmit} className='mt-4'>
           <div className="mb-3 text-start">
               <label htmlFor="emailField" className="form-label" style={{ fontWeight: 'bold' }}>Email</label>
               <input
                   type="email"
                   className="form-control"
                   id="emailField"
                   name="email"
                   value={formData.email}
                   onChange={handleChange}
                   />
                   {formErrors.email && <div className="text-danger">{formErrors.email}</div>}
           </div>
           <div className="mb-3 text-start">
               <label htmlFor="dateField" className="form-label" style={{ fontWeight: 'bold' }}>Date</label>
               <input
                   type="date"
                   className="form-control"
                   id="dateField"
                   name="date"
                   value={formData.date}
                   onChange={handleChange}
               />
                   {formErrors.date && <div className="text-danger">{formErrors.date}</div>}
           </div>
           <div className="mb-3 text-start">
               <label htmlFor="timeField" className="form-label" style={{ fontWeight: 'bold' }}>Time</label>
               <input
                   type="time"
                   className="form-control"
                   id="timeField"
                   name="time"
                   value={formData.time}
                   onChange={handleChange}
               />
                   {formErrors.time && <div className="text-danger">{formErrors.time}</div>}

           </div>
           <div className="mb-3 text-start">
               <label htmlFor="messageField" className="form-label" style={{ fontWeight: 'bold' }}>Message</label>
               <textarea
                   className="form-control"
                   id="messageField"
                   name="message"
                   value={formData.message}
                   onChange={handleChange}
                   rows={4}
               ></textarea>
                   {formErrors.message && <div className="text-danger">{formErrors.message}</div>}
           </div>
           <div className="text-center">
               <button type="submit" className="btn btn-primary w-100">Submit</button>
           </div>
       </form>
   </div>
</div>
    );
};

export default Dashboard;
