import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../constants';

const GoogleCallbackComponent = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const callBackend = async () => {
      const currentUrl = window.location.href;
      const params = currentUrl.split('?')[1];

      try {
        const response = await fetch(`${API_URL}/auth/google/callback?${params}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const responseData = await response.json();
        console.log('Response from Laravel backend:', responseData);

        if (responseData && responseData.token) {
            localStorage.setItem('token', responseData.token);
            setLoading(false);
            navigate(`/dashboard?data=${JSON.stringify(responseData)}`); // Passing responseData as a query parameter
          }
      } catch (error) {
        console.error('Error occurred:', error);
        setLoading(false);
      }
    };

    callBackend();
  }, [navigate]);

  return (
    <div>
      {loading ? (
        <h2>Loading...</h2>
      ) : (
        <>
          <h2>Google Callback Component</h2>
        </>
      )}
    </div>
  );
};

export default GoogleCallbackComponent;
