import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { API_URL } from '../../constants';

const LoginForm = () => {
  const [googleUrl, setGoogleUrl] = useState('');

  const handleGoogleLogin = async () => {
    try {
      const response = await axios.get(`${API_URL}/auth/google/url`);
      if (response.data && response.data.url) {
        console.log('url:',response.data.url);
        window.location.href = response.data.url; 
      }
    } catch (error) {
      console.error('Error fetching Google login URL:', error);
    }
  };

  const handCleverLogin = async () => {
    try {
      const response = await axios.get(`${API_URL}/auth/clever/url`);
      if (response.data && response.data.url) {
        console.log('url:',response.data.url);
        window.location.href = response.data.url; 
      }
    } catch (error) {
      console.error('Error fetching Clever login URL:', error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Login</h2>
              <div className="d-grid gap-2 mb-3">
                <button className="btn btn-danger" type="button" onClick={handleGoogleLogin}>
                  Login with Google
                </button>
              </div>
              <div className="d-grid gap-2 mb-3">
                <button className="btn btn-danger" type="button" style={{background:'#004ee4',border:'none'}} onClick={handCleverLogin}>
                  Login with Clever
                </button>
              </div>
              {/* Clever login button */}
              {/* Add your Clever login functionality here */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
