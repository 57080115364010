import './App.css';
// import Login from '../src/Components/Login/Login';
// import LoginForm from './Components/Login/LoginForm';
import AppRouter from './AppRouter';
function App() {
  return (
    <div className="App">
    <AppRouter/>
    </div>
  );
}

export default App;
